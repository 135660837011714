import styled from '@emotion/styled';

const InputLayout = styled.div<{ width?: string }>`
  display: flex;
  width: ${({ width }) => (width ? width : '100%')};
  border-bottom: 1px solid #b7bbc2;
  background-color: white;
  height: 40px;
`;

export { InputLayout };
