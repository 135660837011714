import { InputBase } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { InputLayout } from '@/components/Organization/CustomInput/styles';
import SvgImage from '@/components/Module/SvgImage';

type P = {
  width?: string;
  placeHolder?: string;
  handleFocus?(): void;
  handleBlur?(): void;
};

const CustomInput = ({ width, placeHolder, handleBlur, handleFocus }: P) => {
  const { control, watch, reset } = useFormContext<{ keyword: string }>();
  const searchString = watch('keyword');

  return (
    <InputLayout width={width}>
      <Controller
        control={control}
        render={({ field }) => (
          <InputBase
            {...field}
            onFocus={handleFocus}
            onBlur={handleBlur}
            autoComplete={'off'}
            sx={{
              flex: 1,
              paddingLeft: '5px',
              height: '40px',
            }}
            placeholder={
              placeHolder ? placeHolder : '부서나 팀명을 검색해 주세요.'
            }
          />
        )}
        name={'keyword'}
      />
      <button style={{ padding: '8px' }} onClick={() => reset()}>
        <SvgImage name={searchString ? 'delete' : 'search'} />
      </button>
    </InputLayout>
  );
};
export default CustomInput;
